import styled from 'styled-components';

const Form = styled.form`
	background-color: #fff;
`;

Form.handleChange = function( e, setValue, index ) {
	const newState = this.state;
	let { value } = e.target;
	const { name } = e.target;

	if ( setValue ) {
		value = setValue;
	}

	if ( typeof index === 'number' ) {
		newState[ name ][ index ] = value;
	} else {
		newState[ name ] = value;
	}

	this.setState( newState );
};

Form.handleFileInput = function( e ) {
	const { name, files } = e.target;
	const file = files[ 0 ];
	if ( !file ) return;
	this.setState( {
		[ name ]: file,
	} );
};

// eslint-disable-next-line max-len
/** @type { StyledComponent<"form", any, {}, never> & { handleChange: ( e: { target: { value: string, name: string } }, setValue?: string, index?: number | string ) => void } } */
export default Form;
