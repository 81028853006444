import React, { useState } from 'react';
import Modal from '../../elements/Modal';
import Button from '../../elements/Button';
import Input from '../forms/inputs/Input';
import styles from './MfaModal.module.scss';
import { H2 } from '../../elements/Headers';
import P from '../../elements/Paragraph';
const MfaModal = ( props ) => {
	const [ mfaToken, setMfaToken ] = useState( null );
	const handleChange = ( e ) => {
		setMfaToken( e.target.value );
		props.handleChange( e );
	};
	return (
		<Modal closeable={ true } closeModal={ props.closeModal }>
			<div className={ styles[ 'mfa-modal-container' ] }>
				<H2 className={ styles[ 'mfa-modal-header' ] }>Please enter your code:</H2>
				<P>
					We sent you an email with a verification code. The code expires in 15
					minutes.
				</P>
				<Input
					className={ styles[ 'mfa-input' ] }
					id='mfa-input'
					type='number'
					value={ mfaToken }
					onChange={ handleChange }
				/>
				<Button
					id='login-button__submit'
					className='login-button__submit'
					type='submit'
					text='Sign In'
					onClick={ props.login }
					disabled={ !mfaToken }
				/>
			</div>
		</Modal>
	);
};

export default MfaModal;
