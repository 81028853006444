import styled from 'styled-components';
import P from '../../../elements/Paragraph';

const StyledFooter = styled.footer`
	width: 100%;
	height: 3.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${ ( { theme } ) => theme.darkBlue };
	text-align: center;
`;

const Footer = () => (
	<StyledFooter id='footer'>
		<P className='p--white'>© { new Date().getUTCFullYear() } Rock Paper Coin</P>
	</StyledFooter>
);

export default Footer;
